// --------------------------------------------------------------------------
// -- You can add your custom CSS here and it will overwrite template styles.
// --------------------------------------------------------------------------

@import "./variables";
@import "./mixins/btn";
@import "./mixins/scheme";
@import "./mixins/breakpoints";

.progress {
  height: 0.25rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

.build-kit-dropdowns-container {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}

.build-kit-buttons-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 1rem;

  .next,
  .back,
  .reset {
    font-weight: $font-weight-medium;
    margin: 0 0 0 0.25rem;
    padding: 0.75rem 1.5rem;
  }

  .request-quote-block {
    align-self: center;
    //width: 100%;
    //flex-basis: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .build-kit-dropdowns-container {
    display: flex;
    flex-direction: column;
  }

  .build-kit-buttons-container {
    .back,
    .reset,
    .next {
      flex-basis: 100%;
      padding: 1rem 2rem;
    }

    .request-quote-block {
      order: 1;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      width: 100%;
      padding-bottom: 1rem;
    }

    .next {
      order: 2;
      margin: 0 0 0.5rem 0;
    }

    .back {
      order: 3;
      margin: 0 0 0.5rem 0;
    }

    .reset {
      margin: 0;
      order: 4;
    }
  }
}

.about-us {
  .img-container {
    width: 40%;
    order: 1;

    img {
      float: right;
      width: 320px;
      margin-right: 4rem;
    }
  }
  .text-container {
    align-self: center;
    width: 60%;
    order: 2;
    max-width: 640px;
  }
}

@include media-breakpoint-down(md) {
  .about-us {
    .img-container {
      width: 100%;

      img {
        display: none;
        margin: 0 auto;
      }
    }
    .text-container {
      width: 100%;
      align-self: flex-start;
    }
  }
}

.banner-wrapper {
  padding: 8rem 2rem;

  h2 {
    font-size: 3rem;
    color: #fff;
    font-weight: 900;
  }

  p {
    line-height: 1.7rem;
    font-size: 1.2rem;
    color: #e1e1e1;
  }
}

.banner-small-wrapper {
  padding: 4rem 2rem;
}

@include media-breakpoint-down(xs) {
  .banner-wrapper {
    padding: 4rem 2rem;

    h2 {
      font-size: 2.5rem;
    }

    p {
      line-height: 1.5rem;
      font-size: 1rem;
    }
  }

  .banner-small-wrapper {
    padding: 3rem 2rem;

    h2 {
      font-size: 2.5rem;
      color: #fff;
      font-weight: 900;
    }

    p {
      line-height: 1.7rem;
      font-size: 1.2rem;
      color: #e1e1e1;
    }
  }
}

// Disable Topbar
// .header {
//   grid-template-rows: 0px auto auto !important;
// }
// .topbar {
//   display: none !important;
// }

.block-finder {
  position: relative;
  height: 300px;
  overflow: hidden;
  padding-top: 24px;
  padding-bottom: 60px;
  display: flex;
  align-items: center;
  background: $block-finder-bg-color;
}

.cursor-pointer {
  cursor: pointer;
}

// .file-input-btn-wrap {
//   position: relative;
// }

.file-input-btn-wrap input[type="file"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

.block-brands__list {
  border: none;
}

.block-brands__item-link {
  img {
    filter: none;
    opacity: 0.8;
  }
}

.home-section__title {
  font-size: 32px;
  font-weight: 900;
  margin: 0;
  position: relative;
  top: 2px;
}

@include media-breakpoint-down(md) {
  .product--layout--full {
    .product__info {
      margin-left: 0 !important;
    }
  }
}

.product__prices-stock {
  padding-bottom: 4px;
}

.product__fit {
  margin-bottom: 6px !important;
  margin-left: 0 !important;
}

.product__info-body {
  padding-top: 12px;
  padding-bottom: 12px;
}

.product--layout--full {
  .product__actions-item--addtocart {
    flex-grow: 1 !important;
  }
}

/* For react-transition group */

.transition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.transition-exit {
  opacity: 1;
}
.transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* styles for inputs in select linkage kit by dimensions */
.slkbd {
  label {
    line-height: 2.5rem;
    margin-right: 10px;
  }

  .input {
    border-radius: 2px 0 0 2px;
    padding: 0 6px;
    height: 2.5rem;
    width: 100%;
    border: 2px solid hsl(0, 0%, 75%);
    background: #fff;
  }

  .short-input {
    border-radius: 2px 0 0 2px;
    padding: 0 6px;
    height: 2.5rem;
    width: 160px;
    border: 2px solid hsl(0, 0%, 75%);
    background: #fff;
  }

  .split-input {
    border-radius: 2px 0 0 2px;
    padding: 0 6px;
    height: 2.5rem;
    min-width: 32px;
    width: 80px;
    border-top: 2px solid hsl(0, 0%, 75%);
    border-bottom: 2px solid hsl(0, 0%, 75%);
    border-left: 2px solid hsl(0, 0%, 75%);
    border-right: none;
    background: #fff;
  }
}

.actuator-selection-type-btns-container,
.valve-selection-type-btns-container {
  display: flex;
  text-align: center;

  button {
    min-width: 225px;
    font-weight: 400;
    margin: 0.2rem;
    white-space: nowrap;
    height: 4rem;
    color: #000;
    background-color: #ffffff;
    border: 2px solid #4b4d4f;
  }
}

@include media-breakpoint-down(sm) {
  .actuator-selection-type-btns-container,
  .valve-selection-type-btns-container {
    button {
      flex-basis: 100%;
      text-align: center;
      width: 100%;
      max-width: 100%;
      margin: 0.25rem 0;
    }
  }
}

.noselect {
  user-select: none;
}

.bg-white {
  background-color: #ffffff;
}
