/*
// .block-products-carousel
*/
@import '../variables';
@import '../mixins/loader';
@import '../mixins/direction';
@import '../mixins/product-card';


$local-carousel-expand: 10px;


.block-products-carousel {
    --block-products-carousel-gutter: 20px;
}
.block-products-carousel__carousel {
    position: relative;

    .slick-track {
        display: flex;
        align-items: stretch;
    }

    .slick-list {
        margin: (-$local-carousel-expand) calc(var(--block-products-carousel-gutter) / 2 * -1);
        padding: $local-carousel-expand 0;
    }
    .slick-slide {
        padding: 0 calc(var(--block-products-carousel-gutter) / 2);
        height: auto;
        display: flex;

        & > div,
        & > div > div {
            width: 100%;
            display: flex !important;
            align-items: stretch;
        }
    }
    .slick-slide,
    .slick-slide > div > div {
        &:focus {
            outline: none;
        }
    }
}
.block-products-carousel__carousel-loader {
    @include loader-overlay(map_get($body-scheme, main), $local-carousel-expand, $local-carousel-expand, 100px, 2px, $body-loader-color);

    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition:
        opacity .3s,
        visibility 0s .3s;
}
.block-products-carousel__carousel--loading {
    &:not(.block-products-carousel__carousel--has-items) {
        min-height: 120px;
    }

    .block-products-carousel__carousel-loader {
        visibility: visible;
        transition-delay: 0s;
        opacity: 1;
    }
}
.block-products-carousel__column {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.block-products-carousel__cell {
    display: flex;
    width: 100%;
    flex-basis: 100%;
    flex-grow: 1;

    .product-card {
        width: 100%;
    }
}


.block-products-carousel[data-layout="grid-4"],
.block-products-carousel[data-layout="grid-5"] {
    .block-products-carousel__cell + .block-products-carousel__cell {
        margin-top: 20px;
    }
}
.block-products-carousel[data-layout="grid-6"] {
    .block-products-carousel__cell + .block-products-carousel__cell {
        margin-top: 16px;
    }
}
.block-products-carousel[data-layout|="horizontal"] {
    --block-products-carousel-gutter: 14px;

    .block-products-carousel__cell + .block-products-carousel__cell {
        margin-top: 14px;
    }
}


.block-products-carousel[data-layout|="grid"] {
    @include media-breakpoint-down(lg) {
        --block-products-carousel-gutter: 16px;
    }
}
