/*
// .cart
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/breakpoints';

.cart {
  display: flex;
}
.cart__table {
  flex-grow: 1;
}
.cart__totals {
  width: 360px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  .cart-totals-card {
    width: 100%;
    order: 1;
    margin-bottom: 20px;
  }

  .coupon-card {
    width: 100%;
    order: 2;
  }

  @include direction {
    #{$margin-inline-start}: 50px;
  }
}

.cart-table__coupon-form {
  padding: 16px;
}

.cart__totals-table {
  width: 100%;
  margin-bottom: 32px;
  font-size: 15px;

  a:hover {
    text-decoration: underline;
  }

  &,
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td {
    display: block;
  }

  tr:after {
    display: block;
    content: '';
    clear: both;
  }

  th,
  td {
    padding: 0;
  }
  th {
    @include direction {
      float: $inline-start;
    }
  }
  td {
    @include direction {
      text-align: $inline-end;
    }
  }

  thead,
  tbody {
    line-height: 20px;
  }

  tbody {
    tr {
      & > * {
        padding-top: 8px;
      }
    }
    tr:last-child {
      & > * {
        padding-bottom: 10px;
      }
    }
  }

  tfoot {
    font-size: 20px;

    th {
      font-weight: $font-weight-medium;
    }

    td,
    th {
      padding-top: 12px;
      border-top: 1px solid map_get($card-scheme, divider);
    }
    td {
      letter-spacing: -0.02em;
    }
  }
}

@include media-breakpoint-down(xl) {
  .cart__table {
    width: 100%;
  }
  .cart__totals {
    @include direction {
      #{$margin-inline-start}: 20px;
    }
  }
}
@include media-breakpoint-down(md) {
  .cart {
    flex-wrap: wrap;
  }

  .cart__totals {
    width: 360px;

    .cart-totals-card {
      margin-top: 20px;
      order: 2;
    }

    .coupon-card {
      margin-top: 20px;
      order: 1;
    }

    @include direction {
      #{$margin-inline-start}: auto;
    }
  }
}
@include media-breakpoint-down(sm) {
  .cart__totals {
    width: 100%;
  }
}
