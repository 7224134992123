/*
// .alert
*/
@import '../variables';
@import '../mixins/direction';

.alert {
  border: none;
  border-radius: 2px;
  font-size: 15px;
  line-height: 20px;
  padding: 0.75rem 1rem;

  a {
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    color: currentColor;
    background: $alert-link-default-bg;
    border-radius: 1.5px;
    padding: 0 3px;

    &:hover {
      background: $alert-link-hover-bg;
    }
    &:active {
      background: $alert-link-active-bg;
    }
  }
  hr {
    border-color: currentColor;
    opacity: 0.12;
  }

  .close svg {
    display: block;
  }
}

// styles
@each $name, $scheme in $alerts {
  .alert-#{$name} {
    border: 1px solid map_get($scheme, main);
    border-left: 5px solid map_get($scheme, opposite);
    color: map_get($scheme, opposite);
  }
}

// sizes
.alert-xs {
  font-size: 14px;
  line-height: 1.25;
  padding: 6px 9px;
}
.alert-sm {
  font-size: 15px;
  padding: 7px 12px;
}
.alert-lg {
  font-size: 16px;
  padding: 1.125rem 1.375rem;
}

.alert-dismissible {
  @include direction {
    #{$padding-inline-end}: 3rem;
  }
}
.alert-dismissible .close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;
  transition: all 0.2s;
  fill: $alert-close-button-default-color;
  background: $alert-close-button-default-bg;

  @include direction {
    #{$inset-inline-end}: 0;
    #{$border-start-end-radius}: 1.5px;
    #{$border-end-end-radius}: 1.5px;
  }

  &:focus {
    outline: none;
  }
}
.alert-dismissible .close:not(:disabled):not(.disabled):focus,
.alert-dismissible .close:not(:disabled):not(.disabled):hover {
  background: $alert-close-button-hover-bg;
  fill: $alert-close-button-hover-color;
  opacity: 1;
}
